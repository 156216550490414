<script setup></script>

<template>
    <div
        style="background-image: url(https://images.pexels.com/photos/8123311/pexels-photo-8123311.jpeg?auto=compress)"
        class="inset-0 fixed left-0 right-0 h-screen w-screen bg-theme-primary flex flex-col items-center justify-center"
    >
        <slot></slot>
    </div>
</template>

<style scoped></style>
